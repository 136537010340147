@font-face {
  font-family: "Nunito Sans";
  src: url("./assets/fonts/NunitoSans/NunitoSans.ttf") format("truetype");
  font-weight: 100 1000;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Nunito Sans";
  src: url("./assets/fonts/NunitoSans/NunitoSans-Italic.ttf") format("truetype");
  font-weight: 100 1000;
  font-style: italic;
  font-display: block;
}
