@use "@erictooth/css-utils/globals/reset";
@use "./assets/fonts/NunitoSans/NunitoSans";
@use "./assets/fonts/LibreBaskerville/LibreBaskerville";
@use "@erictooth/ds/scss/tokens/bg" as bg;
@use "@erictooth/ds/scss/tokens/border" as border;
@use "@erictooth/ds/scss/tokens/color" as color;
@use "@erictooth/ds/scss/tokens/scale" as scale;
@use "@erictooth/ds/scss/tokens/text" as text;
@use "@erictooth/ds/scss/components/index" as components;
@use "@erictooth/ds/scss/utility-classes/index" as utilClasses;
@use "@erictooth/css-utils/utility-classes";

@mixin textScale {
  --text-size--xxs: 11px;
  --text-size--xs: 12px;
  --text-size--sm: 14px;
  --text-size--base: 16px;
  --text-size--lg: 18px;
  --text-size--xl: 22px;
  --text-size--xxl: 24px;
}

:root {
  background-color: var(--bg--sunken);
  color: var(--color--sunken);
  font-family: "Nunito Sans", sans-serif;
  font-size: var(--text-size--base);
  @include color.color;
  @include scale.scale;
  @include textScale;
  @include text.textWeight;
  --text-weight--emphasized: 600;
}

:root {
  @include bg.bgLight;
  @include border.borderLight;
  @include color.colorLight;
  @include components.themeLight;
}

.font-serif-heading {
  font-family: "Libre Baskerville";
}
