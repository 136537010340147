blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

ol, ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

body {
  margin: 0;
}

a {
  color: inherit;
}

@font-face {
  font-family: Nunito Sans;
  src: url("NunitoSans.8729677f.ttf") format("truetype");
  font-weight: 100 1000;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: Nunito Sans;
  src: url("NunitoSans-Italic.13e9aa36.ttf") format("truetype");
  font-weight: 100 1000;
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: Libre Baskerville;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("kmKnZrc3Hgbbcjq75U4uslyuy4kn0qNXaxMICA.6030c450.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Libre Baskerville;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("kmKnZrc3Hgbbcjq75U4uslyuy4kn0qNZaxM.008e2423.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.animation-fadein {
  will-change: opacity;
  opacity: 0;
  transition-property: opacity;
  transition-duration: .1s;
}

.animation-fadein-enter, .animation-fadein-appear {
  opacity: 0;
}

.animation-fadein-enter-active, .animation-fadein-appear-active, .animation-fadein-appear-done, .animation-fadein-enter-done, .animation-fadein-exit {
  opacity: 1;
}

.animation-fadein-exit-active {
  opacity: 0;
}

.animation-fadein-exit-done {
  display: none;
}

.avatar {
  text-transform: uppercase;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  block-size: 2.5em;
  inline-size: 2.5em;
  font-weight: 700;
  line-height: normal;
  display: inline-flex;
  overflow: hidden;
}

.avatar > * {
  object-fit: contain;
  block-size: 100%;
  inline-size: 100%;
}

.btn-group :not(.btn--icon-only) + :not(.btn--icon-only) {
  margin-inline-start: var(--scale-1);
}

.btn {
  gap: var(--scale-1);
  block-size: var(--scale-4);
  padding-inline: var(--scale-105);
  background-color: inherit;
  color: inherit;
  cursor: pointer;
  font-weight: var(--text-weight--emphasized);
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid #0000;
  border-radius: 4px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: background .1s, color .1s;
  display: inline-flex;
}

.btn--icon-only {
  padding-inline: var(--scale-1);
}

.elevation-sunken {
  background-color: var(--bg--sunken);
  color: var(--color--sunken);
}

.elevation-default {
  background-color: var(--bg--default);
  color: var(--color--default);
}

.elevation-raised {
  background-color: var(--bg--raised);
  color: var(--color--raised);
  filter: drop-shadow(1px 2px 3px #80808033) drop-shadow(2px 4px 6px #8080801a) drop-shadow(4px 8px 12px #8080801a);
  border-radius: 4px;
  overflow: hidden;
}

.elevation-overlay {
  background-color: var(--bg--overlay);
  color: var(--color--overlay);
}

.form-control {
  background: var(--bg--default);
  border: 2px solid var(--border-color--default);
  box-sizing: border-box;
  color: var(--color--default);
  border-radius: 4px;
  outline: none;
  overflow: hidden;
}

.form-control:focus, .form-control:focus-within {
  border-color: var(--border-color--focused);
  box-shadow: 0 0 0 .15rem #0d6efd40;
}

.form-control[aria-invalid="true"], .form-control--invalid {
  border-color: red;
}

.form-control input {
  appearance: none;
  background: none;
  border: none;
  outline: none;
  flex: 1;
  block-size: 100%;
  padding: 0;
}

.form-control:not([type="radio"]):not([type="checkbox"]) {
  block-size: var(--scale-4);
  padding-inline: var(--scale-1);
  flex-direction: row;
  align-items: center;
  inline-size: 100%;
  display: flex;
}

.form-control[type="radio"], .form-control[type="checkbox"] {
  appearance: none;
  cursor: pointer;
  inline-size: var(--scale-2);
  block-size: var(--scale-2);
  margin: 0;
  display: inline-block;
  position: relative;
}

.form-control[type="checkbox"]:checked:after {
  background-color: var(--color--primary--400);
  content: "";
  border-radius: 2px;
  position: absolute;
  inset: 0;
  transform: scale(.7);
}

.form-control[type="radio"] {
  border-radius: 9999px;
}

.form-control[type="radio"]:checked:after {
  background-color: var(--color--primary--400);
  content: "";
  border-radius: 9999px;
  position: absolute;
  inset: 0;
  transform: scale(.7);
}

fieldset {
  border: 0;
  min-width: 0;
  margin: 0;
  padding: 0;
}

legend {
  float: left;
  margin: 0;
  padding: 0;
  display: table;
}

legend + * {
  clear: both;
}

.form label, .form fieldset {
  gap: var(--scale-05);
  -webkit-user-select: none;
  user-select: none;
  font-weight: var(--text-weight--emphasized);
  flex-direction: column;
  display: flex;
}

.form-vertical {
  gap: var(--scale-105);
  flex-direction: column;
  display: flex;
}

.form-horizontal {
  gap: var(--scale-2);
  flex-direction: row;
  display: flex;
}

.form-horizontal label {
  flex: 1;
}

.form .form-label-horizontal {
  align-items: center;
  gap: var(--scale-1);
  flex-direction: row;
  inline-size: max-content;
  display: flex;
}

.listbox, .listbox-group {
  -webkit-user-select: none;
  user-select: none;
  flex-direction: column;
  display: flex;
}

.listbox-group + .listbox-group, .listbox-group + .listbox-group-label {
  border-top: 1px solid var(--color--grey--200);
}

.listbox-group + .listbox-group:before, .listbox-group + .listbox-group-label:before {
  content: "";
  display: block;
}

.listbox-group + .listbox-group-label:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.listbox-group-label {
  padding-inline: var(--scale-1);
  font-size: var(--text-size--xs);
  color: var(--color--muted);
  padding-block-start: var(--scale-1);
  font-weight: 500;
  position: relative;
}

.listbox-item {
  padding: var(--scale-1);
  justify-items: center;
  display: flex;
}

.listbox-item:hover {
  background-color: var(--color--primary--25);
  color: #000;
}

.listbox-item--active, .listbox-item[aria-selected="true"] {
  background-color: var(--color--primary--50);
  color: #000;
}

.listbox-item:has(input:checked) {
  background-color: var(--color--primary--50);
  color: #000;
}

.prose {
  box-sizing: content-box;
  max-inline-size: 75ch;
}

.prose > * + * {
  margin-block-start: var(--scale-2);
}

.prose h1, .prose h2, .prose h3, .prose h4, .prose h5, .prose h6 {
  font-weight: var(--text-weight--strong);
}

.prose h1 {
  font-size: 2.5em;
}

.prose h2 {
  font-size: 1.75em;
}

.prose h3 {
  font-size: 1.5em;
}

.prose h4 {
  font-size: 1em;
}

.prose li {
  margin-inline-start: var(--scale-3);
}

.prose ul {
  list-style-type: disc;
}

.prose ol {
  list-style-type: decimal;
}

.bg-hovered {
  background-color: var(--bg--hovered);
}

.color-muted {
  color: var(--color--muted);
}

.text-weight--base {
  font-weight: var(--text-weight--base);
}

.text-weight--emphasized {
  font-weight: var(--text-weight--emphasized);
}

.text-weight--strong {
  font-weight: var(--text-weight--strong);
}

.align-baseline {
  vertical-align: baseline;
}

.border-none {
  border-style: none;
}

.border-solid {
  border-style: solid;
}

.border-transparent {
  border-color: #0000;
}

.self-start {
  align-self: start;
}

.self-end {
  align-self: end;
}

.self-center {
  align-self: center;
}

.self-baseline {
  align-self: baseline;
}

.items-start {
  align-items: start;
}

.items-end {
  align-items: end;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.justify-start {
  justify-content: start;
}

.justify-end {
  justify-content: end;
}

.justify-center {
  justify-content: center;
}

.flex-1 {
  flex: 1;
}

.flex-initial {
  flex: 0 auto;
}

.flex-auto {
  flex: auto;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex {
  display: flex;
}

.grow {
  flex-grow: 1;
}

.shrink {
  flex-shrink: 1;
}

.shrink-0 {
  flex-shrink: 0;
}

.gap-0 {
  gap: var(--scale-0);
}

.gap-25 {
  gap: var(--scale-25);
}

.gap-1 {
  gap: var(--scale-1);
}

.gap-105 {
  gap: var(--scale-105);
}

.gap-2 {
  gap: var(--scale-2);
}

.gap-205 {
  gap: var(--scale-205);
}

.gap-3 {
  gap: var(--scale-3);
}

.gap-4 {
  gap: var(--scale-4);
}

.gap-5 {
  gap: var(--scale-5);
}

.gap-6 {
  gap: var(--scale-6);
}

.gap-7 {
  gap: var(--scale-7);
}

.gap-8 {
  gap: var(--scale-8);
}

.gap-9 {
  gap: var(--scale-9);
}

.gap-10 {
  gap: var(--scale-10);
}

.h-full {
  block-size: 100%;
}

.h-auto {
  block-size: auto;
}

.max-h-max {
  max-block-size: max-content;
}

.m-0 {
  margin: var(--scale-0);
}

.mx-0 {
  margin-inline: var(--scale-0);
}

.my-0 {
  margin-block: var(--scale-0);
}

.mt-0 {
  margin-block-start: var(--scale-0);
}

.mb-0 {
  margin-block-end: var(--scale-0);
}

.ml-0 {
  margin-inline-start: var(--scale-0);
}

.mr-0 {
  margin-inline-end: var(--scale-0);
}

.m-25 {
  margin: var(--scale-25);
}

.mx-25 {
  margin-inline: var(--scale-25);
}

.my-25 {
  margin-block: var(--scale-25);
}

.mt-25 {
  margin-block-start: var(--scale-25);
}

.mb-25 {
  margin-block-end: var(--scale-25);
}

.ml-25 {
  margin-inline-start: var(--scale-25);
}

.mr-25 {
  margin-inline-end: var(--scale-25);
}

.m-1 {
  margin: var(--scale-1);
}

.mx-1 {
  margin-inline: var(--scale-1);
}

.my-1 {
  margin-block: var(--scale-1);
}

.mt-1 {
  margin-block-start: var(--scale-1);
}

.mb-1 {
  margin-block-end: var(--scale-1);
}

.ml-1 {
  margin-inline-start: var(--scale-1);
}

.mr-1 {
  margin-inline-end: var(--scale-1);
}

.m-105 {
  margin: var(--scale-105);
}

.mx-105 {
  margin-inline: var(--scale-105);
}

.my-105 {
  margin-block: var(--scale-105);
}

.mt-105 {
  margin-block-start: var(--scale-105);
}

.mb-105 {
  margin-block-end: var(--scale-105);
}

.ml-105 {
  margin-inline-start: var(--scale-105);
}

.mr-105 {
  margin-inline-end: var(--scale-105);
}

.m-2 {
  margin: var(--scale-2);
}

.mx-2 {
  margin-inline: var(--scale-2);
}

.my-2 {
  margin-block: var(--scale-2);
}

.mt-2 {
  margin-block-start: var(--scale-2);
}

.mb-2 {
  margin-block-end: var(--scale-2);
}

.ml-2 {
  margin-inline-start: var(--scale-2);
}

.mr-2 {
  margin-inline-end: var(--scale-2);
}

.m-205 {
  margin: var(--scale-205);
}

.mx-205 {
  margin-inline: var(--scale-205);
}

.my-205 {
  margin-block: var(--scale-205);
}

.mt-205 {
  margin-block-start: var(--scale-205);
}

.mb-205 {
  margin-block-end: var(--scale-205);
}

.ml-205 {
  margin-inline-start: var(--scale-205);
}

.mr-205 {
  margin-inline-end: var(--scale-205);
}

.m-3 {
  margin: var(--scale-3);
}

.mx-3 {
  margin-inline: var(--scale-3);
}

.my-3 {
  margin-block: var(--scale-3);
}

.mt-3 {
  margin-block-start: var(--scale-3);
}

.mb-3 {
  margin-block-end: var(--scale-3);
}

.ml-3 {
  margin-inline-start: var(--scale-3);
}

.mr-3 {
  margin-inline-end: var(--scale-3);
}

.m-4 {
  margin: var(--scale-4);
}

.mx-4 {
  margin-inline: var(--scale-4);
}

.my-4 {
  margin-block: var(--scale-4);
}

.mt-4 {
  margin-block-start: var(--scale-4);
}

.mb-4 {
  margin-block-end: var(--scale-4);
}

.ml-4 {
  margin-inline-start: var(--scale-4);
}

.mr-4 {
  margin-inline-end: var(--scale-4);
}

.m-5 {
  margin: var(--scale-5);
}

.mx-5 {
  margin-inline: var(--scale-5);
}

.my-5 {
  margin-block: var(--scale-5);
}

.mt-5 {
  margin-block-start: var(--scale-5);
}

.mb-5 {
  margin-block-end: var(--scale-5);
}

.ml-5 {
  margin-inline-start: var(--scale-5);
}

.mr-5 {
  margin-inline-end: var(--scale-5);
}

.m-6 {
  margin: var(--scale-6);
}

.mx-6 {
  margin-inline: var(--scale-6);
}

.my-6 {
  margin-block: var(--scale-6);
}

.mt-6 {
  margin-block-start: var(--scale-6);
}

.mb-6 {
  margin-block-end: var(--scale-6);
}

.ml-6 {
  margin-inline-start: var(--scale-6);
}

.mr-6 {
  margin-inline-end: var(--scale-6);
}

.m-7 {
  margin: var(--scale-7);
}

.mx-7 {
  margin-inline: var(--scale-7);
}

.my-7 {
  margin-block: var(--scale-7);
}

.mt-7 {
  margin-block-start: var(--scale-7);
}

.mb-7 {
  margin-block-end: var(--scale-7);
}

.ml-7 {
  margin-inline-start: var(--scale-7);
}

.mr-7 {
  margin-inline-end: var(--scale-7);
}

.m-8 {
  margin: var(--scale-8);
}

.mx-8 {
  margin-inline: var(--scale-8);
}

.my-8 {
  margin-block: var(--scale-8);
}

.mt-8 {
  margin-block-start: var(--scale-8);
}

.mb-8 {
  margin-block-end: var(--scale-8);
}

.ml-8 {
  margin-inline-start: var(--scale-8);
}

.mr-8 {
  margin-inline-end: var(--scale-8);
}

.m-9 {
  margin: var(--scale-9);
}

.mx-9 {
  margin-inline: var(--scale-9);
}

.my-9 {
  margin-block: var(--scale-9);
}

.mt-9 {
  margin-block-start: var(--scale-9);
}

.mb-9 {
  margin-block-end: var(--scale-9);
}

.ml-9 {
  margin-inline-start: var(--scale-9);
}

.mr-9 {
  margin-inline-end: var(--scale-9);
}

.m-10 {
  margin: var(--scale-10);
}

.mx-10 {
  margin-inline: var(--scale-10);
}

.my-10 {
  margin-block: var(--scale-10);
}

.mt-10 {
  margin-block-start: var(--scale-10);
}

.mb-10 {
  margin-block-end: var(--scale-10);
}

.ml-10 {
  margin-inline-start: var(--scale-10);
}

.mr-10 {
  margin-inline-end: var(--scale-10);
}

.m-auto {
  margin: auto;
}

.mx-auto {
  margin-inline: auto;
}

.my-auto {
  margin-block: auto;
}

.mt-auto {
  margin-block-start: auto;
}

.mb-auto {
  margin-block-end: auto;
}

.ml-auto {
  margin-inline-start: auto;
}

.mr-auto {
  margin-inline-end: auto;
}

.p-0 {
  padding: var(--scale-0);
}

.px-0 {
  padding-inline: var(--scale-0);
}

.py-0 {
  padding-block: var(--scale-0);
}

.pt-0 {
  padding-block-start: var(--scale-0);
}

.pb-0 {
  padding-block-end: var(--scale-0);
}

.pl-0 {
  padding-inline-start: var(--scale-0);
}

.pr-0 {
  padding-inline-end: var(--scale-0);
}

.p-25 {
  padding: var(--scale-25);
}

.px-25 {
  padding-inline: var(--scale-25);
}

.py-25 {
  padding-block: var(--scale-25);
}

.pt-25 {
  padding-block-start: var(--scale-25);
}

.pb-25 {
  padding-block-end: var(--scale-25);
}

.pl-25 {
  padding-inline-start: var(--scale-25);
}

.pr-25 {
  padding-inline-end: var(--scale-25);
}

.p-1 {
  padding: var(--scale-1);
}

.px-1 {
  padding-inline: var(--scale-1);
}

.py-1 {
  padding-block: var(--scale-1);
}

.pt-1 {
  padding-block-start: var(--scale-1);
}

.pb-1 {
  padding-block-end: var(--scale-1);
}

.pl-1 {
  padding-inline-start: var(--scale-1);
}

.pr-1 {
  padding-inline-end: var(--scale-1);
}

.p-105 {
  padding: var(--scale-105);
}

.px-105 {
  padding-inline: var(--scale-105);
}

.py-105 {
  padding-block: var(--scale-105);
}

.pt-105 {
  padding-block-start: var(--scale-105);
}

.pb-105 {
  padding-block-end: var(--scale-105);
}

.pl-105 {
  padding-inline-start: var(--scale-105);
}

.pr-105 {
  padding-inline-end: var(--scale-105);
}

.p-2 {
  padding: var(--scale-2);
}

.px-2 {
  padding-inline: var(--scale-2);
}

.py-2 {
  padding-block: var(--scale-2);
}

.pt-2 {
  padding-block-start: var(--scale-2);
}

.pb-2 {
  padding-block-end: var(--scale-2);
}

.pl-2 {
  padding-inline-start: var(--scale-2);
}

.pr-2 {
  padding-inline-end: var(--scale-2);
}

.p-205 {
  padding: var(--scale-205);
}

.px-205 {
  padding-inline: var(--scale-205);
}

.py-205 {
  padding-block: var(--scale-205);
}

.pt-205 {
  padding-block-start: var(--scale-205);
}

.pb-205 {
  padding-block-end: var(--scale-205);
}

.pl-205 {
  padding-inline-start: var(--scale-205);
}

.pr-205 {
  padding-inline-end: var(--scale-205);
}

.p-3 {
  padding: var(--scale-3);
}

.px-3 {
  padding-inline: var(--scale-3);
}

.py-3 {
  padding-block: var(--scale-3);
}

.pt-3 {
  padding-block-start: var(--scale-3);
}

.pb-3 {
  padding-block-end: var(--scale-3);
}

.pl-3 {
  padding-inline-start: var(--scale-3);
}

.pr-3 {
  padding-inline-end: var(--scale-3);
}

.p-4 {
  padding: var(--scale-4);
}

.px-4 {
  padding-inline: var(--scale-4);
}

.py-4 {
  padding-block: var(--scale-4);
}

.pt-4 {
  padding-block-start: var(--scale-4);
}

.pb-4 {
  padding-block-end: var(--scale-4);
}

.pl-4 {
  padding-inline-start: var(--scale-4);
}

.pr-4 {
  padding-inline-end: var(--scale-4);
}

.p-5 {
  padding: var(--scale-5);
}

.px-5 {
  padding-inline: var(--scale-5);
}

.py-5 {
  padding-block: var(--scale-5);
}

.pt-5 {
  padding-block-start: var(--scale-5);
}

.pb-5 {
  padding-block-end: var(--scale-5);
}

.pl-5 {
  padding-inline-start: var(--scale-5);
}

.pr-5 {
  padding-inline-end: var(--scale-5);
}

.p-6 {
  padding: var(--scale-6);
}

.px-6 {
  padding-inline: var(--scale-6);
}

.py-6 {
  padding-block: var(--scale-6);
}

.pt-6 {
  padding-block-start: var(--scale-6);
}

.pb-6 {
  padding-block-end: var(--scale-6);
}

.pl-6 {
  padding-inline-start: var(--scale-6);
}

.pr-6 {
  padding-inline-end: var(--scale-6);
}

.p-7 {
  padding: var(--scale-7);
}

.px-7 {
  padding-inline: var(--scale-7);
}

.py-7 {
  padding-block: var(--scale-7);
}

.pt-7 {
  padding-block-start: var(--scale-7);
}

.pb-7 {
  padding-block-end: var(--scale-7);
}

.pl-7 {
  padding-inline-start: var(--scale-7);
}

.pr-7 {
  padding-inline-end: var(--scale-7);
}

.p-8 {
  padding: var(--scale-8);
}

.px-8 {
  padding-inline: var(--scale-8);
}

.py-8 {
  padding-block: var(--scale-8);
}

.pt-8 {
  padding-block-start: var(--scale-8);
}

.pb-8 {
  padding-block-end: var(--scale-8);
}

.pl-8 {
  padding-inline-start: var(--scale-8);
}

.pr-8 {
  padding-inline-end: var(--scale-8);
}

.p-9 {
  padding: var(--scale-9);
}

.px-9 {
  padding-inline: var(--scale-9);
}

.py-9 {
  padding-block: var(--scale-9);
}

.pt-9 {
  padding-block-start: var(--scale-9);
}

.pb-9 {
  padding-block-end: var(--scale-9);
}

.pl-9 {
  padding-inline-start: var(--scale-9);
}

.pr-9 {
  padding-inline-end: var(--scale-9);
}

.p-10 {
  padding: var(--scale-10);
}

.px-10 {
  padding-inline: var(--scale-10);
}

.py-10 {
  padding-block: var(--scale-10);
}

.pt-10 {
  padding-block-start: var(--scale-10);
}

.pb-10 {
  padding-block-end: var(--scale-10);
}

.pl-10 {
  padding-inline-start: var(--scale-10);
}

.pr-10 {
  padding-inline-end: var(--scale-10);
}

.no-select {
  -webkit-user-select: none;
  user-select: none;
}

.text-xxs {
  font-size: var(--text-size--xxs);
}

.text-xs {
  font-size: var(--text-size--xs);
}

.text-sm {
  font-size: var(--text-size--sm);
}

.text-base {
  font-size: var(--text-size--base);
}

.text-lg {
  font-size: var(--text-size--lg);
}

.text-xl {
  font-size: var(--text-size--xl);
}

.text-xxl {
  font-size: var(--text-size--xxl);
}

.text-start, .text-left {
  text-align: start;
}

.text-end, .text-right {
  text-align: end;
}

.text-center {
  text-align: center;
}

.w-full {
  inline-size: 100%;
}

.w-auto {
  inline-size: auto;
}

.max-w-max {
  max-inline-size: max-content;
}

:root {
  background-color: var(--bg--sunken);
  color: var(--color--sunken);
  font-family: Nunito Sans, sans-serif;
  font-size: var(--text-size--base);
  --color--blue--25: #f5faff;
  --color--blue--50: #eff8ff;
  --color--blue--100: #d1e9ff;
  --color--blue--200: #b2ddff;
  --color--blue--300: #84caff;
  --color--blue--400: #53b1fd;
  --color--blue--500: #2e90fa;
  --color--blue--600: #1570ef;
  --color--blue--700: #175cd3;
  --color--blue--800: #1849a9;
  --color--blue--900: #184175;
  --color--primary--25: var(--color--blue--25);
  --color--primary--50: var(--color--blue--50);
  --color--primary--100: var(--color--blue--100);
  --color--primary--200: var(--color--blue--200);
  --color--primary--300: var(--color--blue--300);
  --color--primary--400: var(--color--blue--400);
  --color--primary--500: var(--color--blue--500);
  --color--primary--600: var(--color--blue--600);
  --color--primary--700: var(--color--blue--700);
  --color--primary--800: var(--color--blue--800);
  --color--primary--900: var(--color--blue--900);
  --color--grey--25: #fcfcfd;
  --color--grey--50: #f9fafb;
  --color--grey--100: #f2f4f7;
  --color--grey--200: #e4e7ec;
  --color--grey--300: #d0d5dd;
  --color--grey--400: #98a2b3;
  --color--grey--500: #667085;
  --color--grey--600: #475467;
  --color--grey--700: #344054;
  --color--grey--800: #1d2939;
  --color--grey--900: #101828;
  --color--green--500: #11b76a;
  --color--green--600: #059855;
  --color--green--700: #017a48;
  --color--green--800: #06603a;
  --color--red--600: #d92d20;
  --color--red--700: #b42318;
  --color--red--800: #912018;
  --scale-0: 0;
  --scale-1: 8px;
  --scale-025: calc(var(--scale-1) * .25);
  --scale-05: calc(var(--scale-1) * .5);
  --scale-105: calc(var(--scale-1) * 1.5);
  --scale-2: calc(var(--scale-1) * 2);
  --scale-205: calc(var(--scale-1) * 2.5);
  --scale-3: calc(var(--scale-1) * 3);
  --scale-4: calc(var(--scale-1) * 4);
  --scale-405: calc(var(--scale-1) * 4.5);
  --scale-5: calc(var(--scale-1) * 5);
  --scale-505: calc(var(--scale-1) * 5.5);
  --scale-6: calc(var(--scale-1) * 6);
  --scale-7: calc(var(--scale-1) * 7);
  --scale-8: calc(var(--scale-1) * 8);
  --scale-9: calc(var(--scale-1) * 9);
  --scale-905: calc(var(--scale-1) * 9.5);
  --scale-10: calc(var(--scale-1) * 10);
  --text-size--xxs: 11px;
  --text-size--xs: 12px;
  --text-size--sm: 14px;
  --text-size--base: 16px;
  --text-size--lg: 18px;
  --text-size--xl: 22px;
  --text-size--xxl: 24px;
  --text-weight--base: 400;
  --text-weight--emphasized: 500;
  --text-weight--strong: 700;
  --text-weight--emphasized: 600;
}

@supports (color: lab(0% 0 0)) {
  :root {
    --color--blue--25: lab(98.0021% -1.03509 -3.00829);
    --color--blue--50: lab(97.0472% -2.04828 -4.32014);
    --color--blue--100: lab(91.079% -4.79108 -13.3627);
    --color--blue--200: lab(85.9031% -8.68374 -21.391);
    --color--blue--300: lab(78.1869% -12.1658 -33.3896);
    --color--blue--400: lab(69.0336% -10.7071 -46.7657);
    --color--blue--500: lab(58.4129% .663549 -61.9776);
    --color--blue--600: lab(48.2702% 13.5424 -72.3104);
    --color--blue--700: lab(40.9229% 16.2071 -68.1684);
    --color--blue--800: lab(32.6665% 13.9337 -57.1569);
    --color--blue--900: lab(26.9324% 1.11495 -34.6066);
    --color--grey--25: lab(98.9822% .0993609 -.364053);
    --color--grey--50: lab(98.2132% -.247031 -.706708);
    --color--grey--100: lab(96.1004% -.316113 -1.86212);
    --color--grey--200: lab(91.5203% -.339299 -2.62349);
    --color--grey--300: lab(85.0553% -.622958 -4.47322);
    --color--grey--400: lab(66.2304% -.996709 -10.0001);
    --color--grey--500: lab(46.9637% -.190675 -12.9432);
    --color--grey--600: lab(35.1601% -1.51691 -12.6487);
    --color--grey--700: lab(26.674% -.75382 -13.7578);
    --color--grey--800: lab(16.061% -1.38119 -12.047);
    --color--grey--900: lab(8.11736% .576034 -12.2275);
    --color--green--500: lab(65.7147% -53.6037 27.904);
    --color--green--600: lab(55.1521% -47.3458 25.1692);
    --color--green--700: lab(44.7438% -39.8027 18.9409);
    --color--green--800: lab(35.3467% -32.5581 14.5864);
    --color--red--600: lab(48.763% 65.1408 51.5164);
    --color--red--700: lab(40.2673% 56.7234 44.986);
    --color--red--800: lab(32.679% 46.7655 35.2838);
  }
}

:root {
  --bg--sunken: var(--color--grey--100);
  --bg--default: var(--color--grey--25);
  --bg--raised: var(--color--grey--25);
  --bg--overlay: var(--color--grey--25);
  --bg--hovered: var(--color--primary--25);
  --bg--active: var(--color--primary--50);
  --border-color--default: var(--color--grey--300);
  --border-color--focused: var(--color--primary--500);
  --color--muted: var(--color--grey--500);
  --color--sunken: var(--color--grey--900);
  --color--default: var(--color--grey--900);
  --color--raised: var(--color--grey--900);
  --color--overlay: var(--color--grey--900);
  --color--hovered: var(--color--grey--900);
  --color--active: var(--color--grey--900);
}

:root .btn--wt-primary.btn--vt-default {
  background-color: var(--color--primary--600);
  color: #fff;
}

:root .btn--wt-primary.btn--vt-default:hover {
  background-color: var(--color--primary--700);
  color: #fff;
}

:root .btn--wt-primary.btn--vt-default:active {
  background-color: var(--color--primary--800);
  color: #fff;
}

:root .btn--wt-secondary.btn--vt-default {
  border: 1px solid #0000001a;
}

:root .btn--wt-secondary.btn--vt-default:hover {
  background-color: var(--color--primary--25);
  color: #000;
}

:root .btn--wt-tertiary.btn--vt-default:hover {
  color: var(--color--primary--600);
}

.font-serif-heading {
  font-family: Libre Baskerville;
}

/*# sourceMappingURL=login.8183002b.css.map */
